import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./Verification.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userVerify } from "../../Redux/Features/userVerifySlice";

const Verification = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, message, errors, isSuccess, isError } = useSelector(
    (state) => state?.userVerify
  );

  useEffect(() => {
    dispatch(
      userVerify({
        data: {
          navigate,
          code,
        },
      })
    );
  }, []);

  return (
    <div className={styles.verification_container}>
      {isLoading ? (
        <CircularProgress size="3rem" />
      ) : (
        <>
          {isError && (
            <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
              {errors[0]?.message}
            </Typography>
          )}
          {isSuccess && (
            <>
              <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                {message || errors[0]?.message}
              </Typography>
              <Button variant="contained" onClick={() => navigate("/signin")}>
                Sign in
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Verification;
